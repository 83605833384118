import React, { useState } from "react"
import Layout from "../components/layout"
import PcpHero from "../components/PcpHero"
import Breadcrumbs from "../components/Breadcrumbs"
import VideoModule from "../components/VideoModule"
import LensesFilter from "../components/LensesFilter"
import HowHubbleWorksCarousel from "../components/HowHubbleWorksCarousel"
import SEO from "../components/seo"
import avgProducts from "../services/avg-products.json"
import { contactLenses as getContactLenses } from "../components/LensCompare/mockedData"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/pages/hubble-lenses.scss"

import PcpList, { formatPcpItem, filterLensProducts } from "../components/PcpList"
import PcpSection from "../components/PcpSection"
import usePromo from '../utils/usePromo'
import useIsLifemart from "../components/Lifemart/hooks/useIsLifemart";
import useIsMilitary from "../components/Military/hooks/useIsMilitary";

const AllPcp = ()  => {
  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts | How We Make Contact Lenses Easy",
      "description": "At Hubble, we believe buying contact lenses should be easy. That’s why we’ve made Hubble’s personalized subscription service the easiest contacts experience in the world.",
      "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"

    },
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/contact-lenses/",
      "@type": "webpage",
      "name": "Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription",
      "description": "Discover a wide selection of contact lenses online at Hubble Contacts. Find the perfect lenses for your eyes with convenient online ordering and fast delivery.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble"
      }
    }
  ]

  const defaultFilter = {
    brand: [],
    lensType: []
  }
  const [filter, setFilter] = useState(defaultFilter)

  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const contactLenses = getContactLenses(!isLifemart && !isMilitary)
  let products = [...contactLenses]
  avgProducts.forEach(product => {
    product.options.forEach((option, optionIndex) => {
      const pcpItem = formatPcpItem(product, option, optionIndex)
      products.push(pcpItem)
    })
  })
  products = filterLensProducts(products, filter)
  const isPromo = usePromo()

  const promos = [
    {
      index: 5,
      title: "Looking for lenses for astigmatism?",
      text: "Shop our new selection of toric lenses from popular brands like Acuvue and Biotrue and get <strong>35% OFF</strong> your first order.",
      buttonText: "Shop Contacts for Astigmatism",
      linkTo: "/contact-lenses/torics/",
      imageClass: "promo-1"
    },
    {
      index: 9,
      heading: "Hydro by Hubble",
      title: "Now $4.99",
      text: `Our most popular daily lens just got better. Get your first order for 80% off, at just 4.99/eye.`,
      buttonText: "Shop Hydro by Hubble",
      linkTo: "/contact-lenses/hydro-by-hubble-lenses/",
      imageClass: "promo-3"
    },
  ]

  return (
    <Layout>
      <SEO
        title="Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription"
        description="Discover a wide selection of contact lenses online at Hubble Contacts. Find the perfect lenses for your eyes with convenient online ordering and fast delivery."
        schemaMarkup={schemaMarkup}
      />
      <PcpHero
        heroText={<>All Contact<br/> Lenses</>}
        heroImage={"PCP/all-desktop.jpg"}
        background="purple"
        bannerClass="purple promo-banner pcp"
        bannerText="Start your subscription now for <strong>UP TO 60% OFF</strong> your first order."
        shopNowInBanner
      />
      <div className="pcp-container avg">
        <Breadcrumbs links={[{ to: "/", label: "Homepage" }, { label: "All Contact Lenses" }]} />
        <LensesFilter
          type="lenses"
          productsLength={products.length}
          filter={filter}
          setFilter={setFilter}
        />
        <PcpList {...{products, promos}} />
      </div>
      <HowHubbleWorksCarousel color="purple"/>
      <div className="video-container">
        <VideoModule
          type={"contacts"}
          video={"https://www.youtube.com/embed/NKxNYzCRGwg"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
      </div>
      <PcpSection
        title="Contact Lenses Online"
        text={
          `Convenience is at your fingertips with contact lenses through Hubble Contacts.
          Order your contact lenses online today and experience clear, comfortable vision.
          Your eyes deserve the best — contact lenses for quality, affordability, and convenience.`
        }
      />
    </Layout>
  )
}

export default AllPcp
